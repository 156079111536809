export const PLAYER_TAB_NATIVE = 'PLAYER_TAB_NATIVE';
export const PLAYER_TAB_ALTERNATIVE = 'PLAYER_TAB_ALTERNATIVE';
export const PLAYER_TAB_ZOOM = 'PLAYER_TAB_ZOOM';
export const PLAYER_TAB_TRANSLATIONS = 'PLAYER_TAB_TRANSLATIONS';
export const PLAYER_TAB_VIMEO = 'PLAYER_TAB_VIMEO'

export const EVENT_LANG_EN = 'en'
export const EVENT_LANG_ES = 'es'
export const EVENT_LANG_IT = 'it'

export const EVENT_LANG_SK = 'sk'
export const EVENT_LANG_BG = 'bg'
export const EVENT_LANG_FR = 'fr'